export const doliTools = {
  install(app) {
    app.config.globalProperties.$humpToWords = (key) => {
      if (!key) return ''
      return key.toString()
        .replace(/([A-Z])/,' $1')
        .toLowerCase()
        .split(' ')
        .map(word => word[0].toUpperCase() + word.substring(1))
        .join(' ')
    };

    app.config.globalProperties.$underscoreToWords = (key) => {
      if (!key) return ''
      return key.toString()
        .replace(/_/,' ')
        .toLowerCase()
        .split(' ')
        .map(word => word[0].toUpperCase() + word.substring(1))
        .join(' ')
    };
  },
};
