import { createRouter, createWebHistory } from 'vue-router'
const Home = () => import('@/views/Home.vue')
const DefaultContainer = () => import("@/containers/DefaultContainer.vue")
const StoreContainer = () => import("@/containers/StoreContainer.vue")
const AdminContainer = () => import("@/containers/AdminContainer.vue")

import { authGuard } from "@auth0/auth0-vue";

const DEFAULT_TITLE = 'eShopCommand'

const routes = [
  {
    path: '/',
    redirect: '/welcome',
    name: 'Home',
    component: DefaultContainer,
    children: [
      {
        path: 'welcome',
        name: 'Welcome',
        component: Home,
        meta: { title: 'My Stores', requiresAuth: true },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "timecards" */ '../views/Profile.vue'),
        beforeEnter: authGuard,
        meta: { title: 'My Profile', requiresAuth: true },
      },
      {
        path: 'ebay/callback',
        name: 'EbayCallback',
        component: () => import( '../views/EbayCallback.vue'),
        props: true,
        meta: { title: 'eBay Auth', requiresAuth: true },
      },
      {
        path: 'waveapps/callback',
        name: 'WaveappsCallback',
        component: () => import( '../views/WaveappsCallback.vue'),
        props: true,
        meta: { title: 'WaveApps Auth', requiresAuth: true },
      }
    ]
  },

  {
    path: '/store/:storeKey?',
    name: 'Store',
    component: StoreContainer,
    beforeEnter: requireStore,
    props: true,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/Dashboard.vue'),
        meta: { title: 'Dashboard', requiresAuth: true  }
      },
      {
        path: 'orders',
        name: 'ParentOrders',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/Orders.vue'),
        children: [
          {
            path: 'all',
            name: 'Orders',
            component: () => import(/* webpackChunkName: "store" */ '@/components/OrderList.vue'),
            meta: { title: 'All Orders', requiresAuth: true },
          },
          {
            path: 'top',
            name: 'TopOrders',
            component: () => import(/* webpackChunkName: "store" */ '@/components/OrderTopList.vue'),
            meta: { title: 'Top Orders', requiresAuth: true },
          },
        ]

      },
      {
        path: 'inventory',
        name: 'Inventory',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/Inventory.vue'),
        meta: { title: 'Inventory', requiresAuth: true  }
      },
      {
        path: 'expenses',
        name: 'Expenses',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/Expenses.vue'),
        meta: { title: 'Expenses', requiresAuth: true  }
      },
      {
        path: 'accounting',
        name: 'Accounting',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/Accounting.vue'),
        meta: { title: 'Accounting', requiresAuth: true  }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "store" */ '@/views/store/Settings.vue'),
        meta: { title: 'Settings', requiresAuth: true  }
      },
      {
        path: 'delete',
        name: 'DeleteStore',
        component: () => import( '@/views/store/DeleteStore.vue'),
        meta: { title: 'Remove Store', requiresAuth: true  }
      }
    ]
  },

  {
    path: '/admin',
    name: 'Admin',
    redirect: '/admin/config-keys',
    component: AdminContainer,
    beforeEnter: authGuard,
    children: [
      {
        path: 'config-keys',
        name: 'ConfigKeys',
        component: () => import( '@/views/admin/ConfigKeys.vue'),
        meta: { title: 'Admin - Config Keys', requiresAuth: true, roles: ['admin'] },
      },
    ]
  },

  {
    path: "/callback",
    name: "callback",
    component: DefaultContainer
  },
]

const router = createRouter({
  //history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function requireStore(to) {
  return authGuard(to)
  //return next()
}

router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
})

router.beforeEach((to, from, next) => {
  //console.log('store', this.$store.state.eshop.store)
  const titleArray = []
  if (to.meta && to.meta.title) {
    titleArray.push(to.meta.title)
  }
  titleArray.push(DEFAULT_TITLE)
  document.title = titleArray.join(' | ')
  next()
})

export default router
