import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/sass/custom.scss'
// import '@/assets/esc-vars.scss'
// import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap'

import auth from './services/auth'

import VueApexCharts from 'vue3-apexcharts'

// const urlParams = new URLSearchParams(window.location.search);
// const ebayCode = urlParams.get('code');
// if (ebayCode) {
//   store.commit('eshop/setEbayCode', ebayCode);
//   window.location.search = null
// }

import { doliTools } from "@/services/doliTools"

createApp(App)
  .use(router)
  .use(store)
  .use(auth)
  .use(VueApexCharts)
  .use(doliTools)
  .mount('#app')
