import ebay from "@/services/ebay";

export default {
  namespaced: true,
  state: {
    store: null,
    globalConfigs: [
      'WAVE_STORE_ID',
      'WAVE_BUSINESS_ID',
      'WAVE_ANCHOR_ACCOUNT_ID',
      'WAVE_SHIPPING_ACCOUNT_ID',
      'WAVE_SALES_ACCOUNT_ID',
      'WAVE_EBAY_FEES_ACCOUNT_ID',
      'WAVE_AD_FEES_ACCOUNT_ID',
      'ESC_ANCHOR_ACCOUNT_ID',
      'ESC_SHIPPING_ACCOUNT_ID',
      'ESC_SALES_ACCOUNT_ID',
      'ESC_EBAY_FEES_ACCOUNT_ID',
      'ESC_AD_FEES_ACCOUNT_ID',
    ],
    ebayUserToken: null,
    ebayRefreshExpireDate: window.localStorage.getItem('esc-ebay-refresh-expire-date'),
    ebayCheckDate: null,
    transactionCount: 0,
    inventory: [],
  },
  getters: {
    config: state => key => state.store.config[key] || null,
    validEbayRefreshToken: state => new Date() < new Date(state.ebayRefreshExpireDate),
  },
  actions: {
    async getEbayStoreDetails(context, { syncData }) {
      return context.rootState.api.get('ebay/get-store-details', {
        params: {
          sync: syncData ? '1' : '0'
        }
      })
        .then(response => response.data)
        .then(data => {
          return data.store
        }).then(ebayStore => {
          if (syncData && ebayStore) {
            return context.dispatch('loadStore', { ebayStore })
          }
          return ebayStore
        })
    },

    async loadStore({ commit, rootState }, {ebayStore}) {
      // if (!rootState.api) {
      //   console.log('rootState.api not defined??', rootState.api)
      //   return;
      // }
      return rootState.api.get('/store')
        .then(response => response.data)
        .then(st => {
          commit('setStore', st)
          return ebayStore || st
        })
    },

    async createStore({ commit, rootState }, {name}) {
      return rootState.api.post('/stores/store', {
        name: name
      }).then(response => response.data)
        .then(data => data.store)
        .then(store => commit('addStore', store))
    },

    async saveStoreConfig({ state, commit, rootState }, { key, val}) {
      return rootState.api.post('/store/config', {
        storeKey: state.store.id,
        key: key,
        val: val,
      })
        .then(response => response.data)
        .then(data => data.conf)
        .then(conf => {
          const configuration = state.store.config
          configuration[conf.data_key] = conf.data_value
          commit('setStore', {...state.store, config: configuration})
        })
      // .then(store => {
      //   commit('setStore', store)
      //   commit('eshop/updateStores', store)
      // })
    },

    async getTransactions({ state, rootState }, { page, perPage }){
      const ebayService = new ebay(state.store.config)

      return rootState.api.get('transactions/get', {
        params: {page, perPage}
      })
        .then(response => response.data)
        .then(ebayService.transactionsToInputs)
    },

    async checkTransaction( context, {key} ) {
      return context.rootState.api.get('transactions/check', {
        params: {
          transactionId: key
        }
      })
        .then(response => response.data)
        .then(data => data.imported)

    },

    async getOrderTransactions(context, {orderId}){
      return context.rootState.api.get('transactions/order-transactions', {
        params: {
          orderId
        }
      })
        .then(response => response.data)
    },

    async getOrders(context) {
      return context.rootState.api.get('transactions/orders', {
        params: {}
      })
        .then(response => response.data)
    },

    async getSales(context, {fromDate, interval}) {
      if (!fromDate) {
        return Promise.reject(`invalid fromDate ${fromDate}`)
      }
      return context.rootState.api.get('transactions/sales', {
        params: {
          f: fromDate,
          interval
        }
      })
        .then(response => response.data)
    },

    async getEbayData({ state, commit, rootState }, {dates}){
      const ebayService = new ebay(state.store.config)

      return rootState.api.get('ebay/get-data', {params: {dates}})
        .then(response => response.data || {transactions:[]})
        .then(data => data.transactions || data.payouts)
        .then(transactions => ebayService.transactionsToInputs(transactions))
        .then(inputs => {
          commit('setTransactionStats', {last_import_date: null})
          return inputs
        })
    },
    async mapTransaction(context, {transactionId}) {
      return context.rootState.api.get('transactions/map', {
        params: {
          transactionId: transactionId
        }
      })
        .then(response => response.data)
        .then(data => data.imported)
    },
    async addAccountTransaction(context, {input}) {
      return context.rootState.api.post('accounts/add-account-transaction', {
        input: input,
        storeId: context.state.store.id
      })
        .then(response => response.data)
    },
    addWaveInput(context, input) {
      return context.rootState.api.post('waveapps/add-wave-input', { input })
        .then(response => response.data)
    },
    getWaveBusinesses(context) {
      return context.rootState.api.get('waveapps/get-businesses', {})
        .then(response => response.data)
        .then(body => {
          if (body.errors) {
            throw new Error (body.errors[0].message)
          }
          return body.data
        })
        .then(data => data.businesses)
        .then(businesses => businesses.edges || [])
        .then(b => b.map(bus => bus.node))
    },
    async getAccounts(context) {
      return context.rootState.api
        .get('/accounts')
        .then(response => response.data)
    },

    getWaveAccounts(context, page=0) {
      return context.rootState.api.get('/waveapps/get-accounts', {params: {page}})
        .then(response => response.data)
        .then(accounts => {
          context.commit('setStore', {...context.state.store, accounts})
          return accounts
        })
    },

    async getOauthAuthUrl( context, { oauth_vendor } ) {
      return context.rootState.api
        .get('/oauth/get-auth-url', {
          params: {
            oauth_vendor
          }
        })
        .then(response => response.data)
        .then(data => data.authUrl)
    },

    async getOauthTokenFromCode(context, { oauth_vendor, code }) {
      return context.rootState.api.post('/oauth/code-for-token', {
        oauth_vendor,
        code
      })
        .then(response => response.data)
    },

    async getOauthCheckToken(context, { oauth_vendor }) {
      return context.rootState.api.post('/oauth/check-token', {
        oauth_vendor
      })
        .then(response => response.data)
    },

    async getEbayAuthUrl(context) {
      context.rootState.api
        .get('/ebay/get-auth-url')
        .then(response => response.data)
        .then(data => data.authUrl)
    },

    async getEbayItem(context, {itemId}) {
      return context.rootState.api
        .post('/ebay/get-item', {itemId})
        .then(response => response.data)
    },

    async getLastImportDate({commit, rootState}) {
      return rootState.api
        .get('/transactions/get-last-import-date')
        .then(response => response.data)
        .then(data => {
          commit('setTransactionStats', data)
          return data
        })
    },

    async getSellerItems(context, {granularity, limit, page}) {
      return context.rootState.api
        .post('/ebay/get-seller-items', {granularity, limit, page})
        .then(response => response.data)
    },

    async getEbayOrder({ commit, rootState }, {orderId}) {
      return rootState.api
        .post('/ebay/get-order', {orderId})
        .then(response => response.data)
        .catch(error => {
          if ((error.status || error.code || 500) === 401 && error.message === 'ebay-auth-error') {
            // we should try revalidating
            commit('setEbayRefreshExpireDate', null)
          }
          throw error
        })
    },

    async fetchStoredEbayTokens( context ) {
      return context.rootState.api.get('/ebay/get-stored-token')
        .then(response => response.data)
    },

    async getEbayUserToken(context, {code, type}) {
      type = type || 'code'

      return context.rootState.api
        .get('/ebay/callback', {params:{code, type}})
        .then(response => response.data)
        .then(data => data.token)
        .then(token => {
          if (token.access_token) {
            return Promise.resolve({validToken: true, token: token.access_token})
          }
          return Promise.resolve({validToken: false, token: false})
        })
    },
    async getEbayRefreshExpires(context) {
      return context.rootState.api
        .get('/ebay/get-refresh-expires')
        .then(response => response.data)
        .then(data => data.expires_in)
        .then(date => {
          context.commit('setEbayRefreshExpireDate', date)
          return date
        })
        .catch(error => {
          context.commit('setEbayRefreshExpireDate', null)
          throw error
        })
    },

    getInventory(context) {
      return context.rootState.api
        .get('/inventory/items')
        .then(response => response.data)
        .then(data => {
          context.commit('setInventory', data.items)
        })
    },

    saveInventoryItem(context, { item }) {
      return context.rootState.api
        .post('/inventory/save-item', { item })
        .then(response => {
          if (response.error) throw new Error(response.error)
          return response.data
        })
        .then(data => data.id || data.error?.sqlMessage)
    },

    saveInventoryItems(context, { items }) {
      return context.rootState.api
        .post('/inventory/save-items', { items })
        .then(response => {
          if (response.error) throw new Error(response.error)
          return response.data
        })
        .then(data => data.inserts)
    },

    updateInventoryItem(context, { id }) {
      return context.rootState.api
        .post('/inventory/update-item', { id })
        .then(response => response.data)
        .then(data => {
          if (data.item?.ItemID) context.commit('setInventoryItem', data.item)
          return data
        })
    }
  },
  mutations: {
    setInventory (state, items) {
      state.inventory = items || []
    },
    setEbayRefreshExpireDate(state, date) {
      const writeDate = date ? (new Date(date)).toISOString() : null;
      state.ebayRefreshExpireDate = writeDate
      if (date) {
        window.localStorage.setItem('esc-ebay-refresh-expire-date', writeDate.toString())
      } else {
        window.localStorage.removeItem('esc-ebay-refresh-expire-date')
      }
    },
    setStore: (state, payload) => {
      if (!payload) {
        state.store = null
        return
      }
      state.store = payload // {...payload, config: JSON.parse(payload.config || "{}")}
    },
    setTransactionStats(state, payload) {
      if (payload.transaction_count) state.transactionCount = payload.transaction_count || 0
      const date = payload.last_import_date ? new Date(payload.transaction_count) : new Date();
      date.setHours(date.getHours() - 1);
      state.ebayCheckDate = date
    },
    setInventoryItem(state, item) {
      const data = {
        id: item.id || 0,
        title: item.title || '',
        description: item.description || '',
        SKU: item.SKU || '',
        photo_url: item.photo_url || '',
        remote_id: item.remote_id || '',
        sale_price: item.sale_price || 0
      }
      if (!state.inventory) {
        state.inventory = [data]
        return
      }
      if (state.inventory.slice().filter(i => i.remote_id === data.remote_id).length) {
        state.inventory = state.inventory.map(i => {
          return i.remote_id === data.remote_id ? data : i
        })
      } else {
        state.inventory = [data].concat(state.inventory)
      }
    }
  }
}
